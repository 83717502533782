import React from 'react';
import { graphql } from 'gatsby';
import { shape, string } from 'prop-types';

import AlternativeLayout from '../components/layout/AlternativeLayout';
import DataSafety from '../svg/DataSafety';
// import DataSafetyPDF from '../data/Datenschutzerklärung_JiM_Morrowmed.pdf';
import SiteMetadata from '../components/site-metadata';

const Privacy = ({ data }) => (
  <>
    <SiteMetadata pathname="privacy" />
    <AlternativeLayout>
      <section className="py-16">
        <div className="container mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-20 lg:px-8">
          <div className="text-center lg:text-left px-8">
            <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">Datenschutz</h1>
            <p className="text-xl lg:text-xl mt-6 font-light">
              Der Schutz Ihrer Daten und Privatsphäre steht im Mittelpunkt unserer
              Produktentwicklung. Die nachfolgende Datenschutzerklärung gibt einen Überblick über
              die erhobenen Daten, wie wir diese schützen und über Ihre Rechte.
              <br /> Bei allen Datenverarbeitungsvorgängen gehen wir nach den gesetzlichen
              Bestimmungen deutschen und europäischen Rechts vor. <br />
              <br />
              <a href={data.file.publicURL} download>
                Datenschutzerklärung (PDF)
              </a>
            </p>
          </div>
          <div className="flex items-center justify-center px-16 lg:px-8">
            <DataSafety />
          </div>
        </div>
      </section>
      <section className="py-20 lg:pb-16 lg:pt-16" />
    </AlternativeLayout>
  </>
);
Privacy.propTypes = {
  data: shape({ file: shape({ publicURL: string }) }).isRequired
};

export default Privacy;

export const pageQuery = graphql`
  query {
    file(relativePath: { eq: "Datenschutzerklärung_JiM_MorrowMed.pdf" }) {
      publicURL
    }
  }
`;
